body {
  margin: 0;
  overflow-x: hidden; /* Hide horizontal scrollbar */
  font-family: ui-monospace;
}

.textTransformCapitalize {
  text-transform: capitalize;
}

a {
  text-decoration: none;
}

.editorMinHeight {
  min-height: 75vh;
}
